<!-- 底部导航 -->
<template>
	<div class="footer-warp">
		<div class="footer" v-if="!isMobileEnd">
			<div class="content">
				<div class="inner">
					<div class="footer-content">
						<div class="footer-content-item">
							<h5>解决方案</h5>
							<div class="plan-item">
								<div class="first-column">
									<p
										v-for="(i, index) in solutionList.slice(
											0,
											12
										)"
										:key="index"
									>
										<router-link
											:to="`/solution?id=${i.planId}`"
											tag="button"
											>{{ i.planName }}</router-link
										>
									</p>
								</div>
								<div
									v-if="solutionList.length > 12"
									class="second-column"
								>
									<p
										v-for="(i, index) in solutionList.slice(
											12,
											solutionList.length
										)"
										:key="index"
									>
										<router-link
											:to="`/solution?id=${i.planId}`"
											tag="button"
											>{{ i.planName }}</router-link
										>
									</p>
								</div>
							</div>
						</div>
						<div class="footer-content-item">
							<h5>产品中心</h5>
							<div class="plan-item">
								<div class="first-column">
									<p
										v-for="(i, index) in productList.slice(
											0,
											12
										)"
										:key="index"
									>
										<router-link
											:to="`/product?id=${i.productId}`"
											tag="button"
											>{{ i.productName }}</router-link
										>
									</p>
								</div>
								<div
									v-if="productList.length > 12"
									class="second-column"
								>
									<p
										v-for="(i, index) in productList.slice(
											12,
											productList.length
										)"
										:key="index"
									>
										<router-link
											:to="`/product?id=${i.productId}`"
											tag="button"
											>{{ i.productName }}</router-link
										>
									</p>
								</div>
							</div>
						</div>
						<div class="footer-content-item">
							<h5>体验中心</h5>
							<el-row
								v-for="(i, index) in experienceList"
								:key="index"
								><router-link
									:to="
										!mobileTerminal
											? i.keyPath
											: '/experienceDemo'
									"
									tag="button"
									@click.native="setExperienceIndex(i.id)"
									>{{ i.keyName }}</router-link
								></el-row
							>
							<el-row type="flex" class="gzh-card">
								<el-col class="footer-gzh" :span="10" v-viewer
									><img
										v-lazy="footerGzh"
										:key="footerGzh"
										alt="公众号"
								/></el-col>
								<el-col class="footer-imgInfo" :span="14">
									<el-row>关注"捷途慧声"公众号</el-row>
									<el-row style="margin-top: 0.2rem"
										>随时获取最新公司动态</el-row
									>
								</el-col>
							</el-row>
						</div>
						<div class="footer-content-item">
							<h5>关于我们</h5>
							<el-row
								v-for="(i, index) in AboutUsList"
								:key="index"
								><router-link :to="i.keyPath" tag="button">{{
									i.keyName
								}}</router-link></el-row
							>
							<div class="footer-content-item-company">
								<div class="companyInfo">
									<p class="companyInfo-phone">
										0571-86929509
									</p>
									<p class="companyInfo-mail">
										sales@jet-voice.com
									</p>
									<p class="companyInfo-address">
										公司地址：杭州市余杭区利尔达物联网科技园1幢1102室
									</p>
								</div>

								<el-row class="ewm-card">
									<el-col
										class="footer-gzh"
										:span="8"
										v-viewer
										><img
											v-lazy="footerXcx"
											:key="footerXcx"
											alt="小程序"
									/></el-col>
									<el-col class="footer-imgInfo" :span="14">
										<el-row
											>关注"慧听智能语音演示系统"</el-row
										>
										<el-row style="margin-top: 0.22rem"
											>体验公司产品性能</el-row
										>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="left"></div>
			<div class="right"></div>
		</div>
		<div class="footer-768" v-else>
			<div class="footer-content">
				<div class="footer-content-item">
					<h5>解决方案</h5>
					<p v-for="(i, index) in solutionList" :key="index">
						<router-link
							:to="`/solution?id=${i.planId}`"
							tag="button"
							>{{ i.planName }}</router-link
						>
					</p>
				</div>
				<div class="footer-content-item">
					<h5>产品中心</h5>
					<p v-for="(i, index) in productList" :key="index">
						<router-link
							:to="`/product?id=${i.productId}`"
							tag="button"
							>{{ i.productName }}</router-link
						>
					</p>
				</div>
			</div>
			<div class="footer-info">
				<div class="footer-info-item">
					<h5>体验中心</h5>
					<p v-for="(i, index) in experienceList" :key="index">
						<router-link
							:to="'/experienceDemo'"
							tag="button"
							@click.native="setExperienceIndex(i.id)"
							>{{ i.keyName }}</router-link
						>
					</p>
				</div>
				<div class="footer-info-item">
					<h5>关于我们</h5>
					<p v-for="(i, index) in AboutUsList" :key="index">
						<router-link :to="i.keyPath" tag="button">{{
							i.keyName
						}}</router-link>
					</p>
					<div class="footer-info-item-contact">
						<p>0571-86929509</p>
						<p>sales@jet-voice.com</p>
						<p>杭州市余杭区利尔达物联网科技园1幢1102室</p>
					</div>
				</div>
			</div>
			<div class="footer-img">
				<div class="footer-img-item">
					<div class="footer-gzh" v-viewer>
						<img
							v-lazy="footerGzh"
							:key="footerGzh"
							alt="微信公众号"
						/>
					</div>
					<p>微信公众号</p>
				</div>
				<div class="footer-img-item">
					<div class="footer-gzh" v-viewer>
						<img v-lazy="footerXcx" :key="footerXcx" alt="小程序" />
					</div>
					<p>小程序</p>
				</div>
			</div>
		</div>
		<div class="footer-bottom">
			Copyright © 2024 杭州捷途慧声科技有限公司 All rights reserved
			<a href="https://beian.miit.gov.cn/" target="_blank"
				>浙ICP备17040694号</a
			>
		</div>
	</div>
</template>

<script>
import {
	menuSolution,
	menuProduct,
	menuExperience,
	menuAboutUs,
} from '@/utils/mock.js'
export default {
	name: 'BottomNav',
	data() {
		return {
			footerGzh: window.imgUrl + '/gzh.png',
			footerXcx: window.imgUrl + '/xcx.jpg',
			experienceList: menuExperience,
			AboutUsList: menuAboutUs,
			mobileTerminal: false,
		}
	},
	props: {
		productList: {
			type: Array,
			default: menuProduct,
		},
		solutionList: {
			type: Array,
			default: menuSolution,
		},
	},
	methods: {
		setExperienceIndex(index) {
			this.activeIndex = index
		},
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		},
	},
	components: {},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
		activeIndex: {
			get() {
				return this.$store.state.activeIndex
			},
			set(val) {
				this.$store.state.activeIndex = val
			},
		},
	},
	created() {},
	mounted() {
		if ((this, this._isMobile())) {
			this.mobileTerminal = true
		} else {
			this.mobileTerminal = false
		}
	},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.footer-warp {
	background-color: #2a2a2a;
	padding-bottom: 0.22rem;
	.footer {
		// 三栏都需要浮动
		.center,
		.left,
		.right {
			float: left;
		}
		// 左右两栏固定宽度
		.left,
		.right {
			width: 3.15rem;
			background-color: #fff;
		}
		// 中间栏  因为需要自适应所以设置宽度100%
		.center {
			width: 100%;
		}
		.left {
			margin-left: -100%;
		}
		.right {
			margin-left: -3.15rem;
		}
		.inner {
			margin: 0 3.15rem;
		}

		.content {
			width: 100%;
			font-size: 0.14rem;
			.footer-content {
				padding-top: 0.4rem;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				&-item {
					width: 25%;
					position: relative;
					h5 {
						font-size: 0.16rem;
						font-family: Source Han Sans, Source Han Sans CN;
						font-weight: 700;
						color: #ffffff;
						line-height: 1;
						margin: 0 0 0.16rem 0;
					}
					button {
						padding: 0;
						border-width: 0px;
						color: #fff;
						background-color: transparent;
						border-color: transparent;
						margin-bottom: 0.16rem;
						cursor: pointer;
						&:hover {
							color: #127bf2;
						}
					}
					&:nth-child(1),
					&:nth-child(2) {
						button {
							margin-bottom: 0.12rem;
						}
					}
					.plan-item {
						display: flex;
						.first-column {
							margin-right: 0.2rem;
						}
						.first-column,
						.second-column {
							max-width: calc((100% - 0.2rem) / 2);
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
				.footer-content-item-company {
					position: absolute;
					left: 0;
					.ewm-card {
						margin-top: 0.3rem;
						width: 4.2rem;
					}
				}
			}
			.gzh-card {
				margin-top: 0.58rem;
			}

			.footer-gzh {
				width: 1.19rem;
				height: 1.17rem;
				border-radius: 0.07rem;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.footer-imgInfo {
				color: #ffffff;
				margin-left: 0.16rem;
				padding-top: 0.09rem;
			}
			.companyInfo {
				color: #ffffff;
				&-phone {
					font-size: 0.14rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					line-height: 0.22rem;
				}
				&-mail {
					font-size: 0.14rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					line-height: 0.22rem;
					margin: 0.16rem 0;
				}
				&-address {
					font-size: 0.14rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					line-height: 0.22rem;
				}
			}
		}
	}
	.footer-768 {
		width: 335px;
		min-height: 704px;
		height: auto;
		padding: 0 20px;
		.footer-content {
			height: auto;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding-top: 24px;
			margin-bottom: 40px;
			&-item {
				width: 50%;
				overflow: hidden;
				h5 {
					height: 18px;
					font-size: 14px;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 700;
					color: #ffffff;
					line-height: 18px;
					padding: 0;
					margin: 0;
					margin-bottom: 8px;
				}

				p {
					height: 16px;
					font-size: 12px;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #ffffff;
					line-height: 16px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				button {
					padding: 0;
					border-width: 0px;
					color: #fff;
					background-color: transparent;
					border-color: transparent;
					margin-bottom: 0.16rem;
					cursor: pointer;
				}
			}
		}
		.footer-info {
			height: 150px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 40px;
			&-item {
				width: 50%;
			}
			h5 {
				font-size: 14px;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 700;
				color: #ffffff;
				line-height: 18px;
				padding: 0;
				margin: 0;
				margin-bottom: 8px;
			}
			p {
				font-size: 12px;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #ffffff;
				line-height: 16px;
			}
			button {
				padding: 0;
				border-width: 0px;
				color: #fff;
				background-color: transparent;
				border-color: transparent;
				margin-bottom: 0.16rem;
				cursor: pointer;
			}
		}
		.footer-img {
			height: 110px;
			display: flex;
			margin-bottom: 40px;
			&-item {
				width: 50%;
				.footer-gzh {
					width: 80px;
					height: 80px;
				}
				p {
					width: 80px;
					font-size: 14px;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					color: #ffffff;
					line-height: 18px;
					text-align: center;
					margin-top: 12px;
				}
			}
		}
	}

	.footer-bottom {
		margin-top: 0.56rem;
		font-size: 0.14rem;
		text-align: center;
		font-weight: 400;
		color: #fff;
		font-family: Source Han Sans, Source Han Sans CN;
		a {
			color: #127bf2;
			margin-left: 0.4rem;
			&:hover {
				color: #ff641b;
			}
		}
	}
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
	.footer-warp {
		.footer-bottom {
			margin-top: 12px;
			font-size: 7px;
			a {
				margin-left: 4px;
			}
		}
	}
}
@media screen and (max-width: 540px) {
}
</style>
