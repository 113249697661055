<!-- 返回顶部 -->
<template>
	<el-backtop :visibility-height="400" :bottom="100" :right="30">
		<div class="backTop">
			<div class="backTop-ewm" @click="backTopEwm">
				<i class="iconfont icon-xiaochengxu1"></i>
				<div class="backTop-div">
					<div class="backTop-div-content">
						<div class="backTop-div-left">
							<i class="iconfont icon-shibie"
								><span style="margin-left: 0.05rem"
									>微信扫码体验</span
								></i
							>
							<p>体验本公司产品性能</p>
						</div>
						<div class="backTop-div-right">
							<img :src="footerXcx" alt="小程序" />
						</div>
					</div>
				</div>
			</div>
			<div
				class="backTop-link"
				@click="
					() => {
						this.$router.push('/formSub')
					}
				"
			>
				<i class="iconfont icon-kefu"></i>
			</div>
			<div class="backTop-icon">
				<el-tooltip
					class="item"
					effect="dark"
					content="回到顶部"
					placement="left"
				>
					<i class="iconfont icon-huidaoyuanchujiantou"></i>
				</el-tooltip>
			</div>
		</div>
	</el-backtop>
</template>

<script>
export default {
	name: 'NewsInfo',
	data() {
		return {
			footerXcx: require('@/assets/img/xcx.png'),
		}
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		backTopEwm(e) {
			window.event
				? (window.event.cancelBubble = true)
				: e.stopPropagation()
		},
	},
	components: {},
	computed: {},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.el-backtop {
	background-color: rgba(0, 0, 0, 0);
	box-shadow: none;
}
.backTop {
	width: 0.4rem;
	height: 1.24rem;
	border-radius: 0.2rem;
	background: #dee1e6;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	box-shadow: grey;
	.backTop-ewm {
		width: inherit;
		height: 0.4rem;
		border-radius: 50%;
		text-align: center;
		line-height: 0.4rem;
		margin: 0 auto;
		color: #666666;
		position: relative;
		i {
			font-size: 0.2rem;
		}
		.backTop-div {
			display: none;
			width: 3rem;
			height: 1.5rem;
			box-shadow: 0 0.01rem 0.1rem 0 rgb(0 0 0 / 10%);
			background: #fff;
			border-radius: 0.06rem;
			position: absolute;
			right: 0.52rem;
			bottom: 0;
			z-index: 100;
			padding: 0.1rem;
			overflow: hidden;
			.backTop-div-content {
				height: inherit;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.backTop-div-left {
					width: 1.5rem;
					font-size: 0.14rem;
					i {
						font-size: 0.18rem;
						color: #333333;
					}
					p {
						color: #666666;
					}
				}
				.backTop-div-right {
					// width: 0.9rem;
					height: inherit;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		&:hover {
			color: #127bf2;
			background: #f1f3f4;
			.backTop-div {
				color: #666;
				display: block;
			}
		}
	}
	.backTop-link {
		width: inherit;
		height: 0.4rem;
		border-radius: 50%;
		text-align: center;
		line-height: 0.4rem;
		margin: 0 auto;
		color: #666666;
		position: relative;
		i {
			font-size: 0.2rem;
		}
		&:hover {
			color: #127bf2;
			background: #f1f3f4;
		}
	}
	.backTop-icon {
		width: inherit;
		height: 0.4rem;
		border-radius: 50%;
		text-align: center;
		line-height: 0.4rem;
		background: #f1f3f4;
		box-shadow: 0px 0.02rem 0.03rem rgba(0, 0, 0, 0.2);
		i {
			font-size: 0.2rem;
			color: #666;
		}
		&:hover {
			background: #f1f3f4;
			box-shadow: 0px 0.02rem 0.03rem rgba(0, 0, 0, 0.4);
			i {
				color: #127bf2;
			}
		}
	}
}

@media screen and (max-width: 1080px) {
	// 	.backTop {
	// 		width: 30px;
	// 		height: 62.75px;
	// 		border-radius: 15px;
	// 		.backTop-ewm,
	// 		.backTop-icon {
	// 			height: 30px;
	// 			i {
	// 				font-size: 16px;
	// 				line-height: 162%;
	// 			}
	// 		}
	// 		.backTop-ewm .backTop-div {
	// 			width: 150px;
	// 			height: 65px;
	// 			.backTop-div-left {
	// 				width: 80px;
	// 				p {
	// 					display: none;
	// 				}
	// 			}
	// 			.backTop-div-right {
	// 				width: 60px !important;
	// 				height: 60px !important;
	// 			}
	// 		}
	// 	}
}
@media screen and (max-width: 768px) {
	.el-backtop {
		right: 20px !important;
	}
	.backTop {
		height: 30px;
		.backTop-ewm {
			display: none;
		}
	}
}
</style>
