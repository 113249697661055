//导航-行业解决方案
export const menuSolution = [
	{
		id: '2-1',
		keyName: '智慧警务解决方案',
		keyPath: '/intelligentPolice',
	},
	{ id: '2-2', keyName: '智慧检务解决方案', keyPath: '/examine' },
	{ id: '2-3', keyName: '智慧法院解决方案', keyPath: '/court' },
	{ id: '2-4', keyName: '智慧监察解决方案', keyPath: '/monitor' },
	{ id: '2-5', keyName: '智慧案卷解决方案', keyPath: '/records' },
	{ id: '2-6', keyName: '智能车联解决方案', keyPath: '/carAi' },
	{
		id: '2-7',
		keyName: '智慧教育解决方案',
		keyPath: '/education',
	},
	{
		id: '2-8',
		keyName: '智慧财务解决方案',
		keyPath: '/imageOcr',
	},
	{
		id: '2-9',
		keyName: 'AI+政府解决方案',
		keyPath: '/administration',
	},
	{
		id: '2-10',
		keyName: '智能质检解决方案',
		keyPath: '/qualityInspectionPlan',
	},
]
//导航-产品中心
export const menuProduct = [
	{ id: '3-1', productName: '慧听智能语音审讯系统' },
	{
		id: '3-2',
		productName: '慧听智能语音庭审系统',
	},
	{
		id: '3-3',
		productName: '慧听智能语音助手',
	},
	{
		id: '3-4',
		productName: '慧听智能语音会议系统',
	},
	{ id: '3-5', productName: '慧影智能卷宗' },
	{
		id: '3-6',
		productName: '慧思智能客服系统',
	},
	{
		id: '3-7',
		productName: '慧看票据识别系统',
	},
	{
		id: '3-8',
		productName: '慧看智能文字识别系统',
	},
	{
		id: '3-9',
		productName: '慧听异常声音分析系统',
	},
	{
		id: '3-10',
		productName: '慧听便携式会议一体机',
	},
	{
		id: '3-11',
		productName: '慧听便携式审讯一体机',
	},
	{
		id: '3-12',
		productName: '慧听智能质检系统',
	},
]
//导航-体验中心-跳转链接
export const menuExperience = [
	{ id: 0, keyName: '语音识别合成', keyPath: '/asr&vs' },
	{ id: 1, keyName: '机器翻译MT', keyPath: '/translate' },
	{ id: 2, keyName: '光学字符识别OCR', keyPath: '/ocr' },
	{ id: 3, keyName: '异常声音分析', keyPath: '/voiceAnalyze' },
	{ id: 4, keyName: '更多介绍', keyPath: '/more' },
]
//导航-关于我们-跳转链接
export const menuAboutUs = [
	{ id: '5-1', keyName: '公司介绍', keyPath: '/companyOf' },
	{ id: '5-2', keyName: '新闻资讯', keyPath: '/newsAndTrends' },
	{ id: '5-3', keyName: '联系我们', keyPath: '/companyOf?id=boxContent' },
]

//首页-体验中心
export const homeExperience = [
	{
		name: '语音识别合成',
		iconClass: 'iconfont icon-a-zu69622',
		path: '/asr&vs',
		sketch: '语音流式传输，云端识别',
	},
	{
		name: '机器翻译MT',
		iconClass: 'iconfont icon-a-caozuo-fanyi41',
		path: '/translate',
		sketch: '文字合成准确、清晰',
	},
	{
		name: '光学字符识别OCR',
		iconClass: 'iconfont icon-a-mengbanzu4',
		path: '/ocr',
		sketch: '快速读取图像中的文字信息',
	},
	{
		name: '异常声音分析',
		iconClass: 'iconfont icon-a-zu697',
		path: '/voiceAnalyze',
		sketch: '支持多语种与中文互译',
	},
]
