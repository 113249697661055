<!-- 顶部导航 -->
<template>
	<div
		class="header-wrap"
		:class="{ 'header-lucency': isCurrent || isVisible }"
		@mouseover="handleOpen"
		@mouseout="handleClose"
	>
		<div class="header-logo">
			<img
				v-show="!(isCurrent || isVisible)"
				v-lazy="headerLogo"
				:key="headerLogo"
				alt="logo"
			/>
			<img
				v-show="isCurrent || isVisible"
				v-lazy="headerLogoBlank"
				:key="headerLogoBlank"
				alt="logo"
			/>
		</div>
		<div class="header-navMenu">
			<el-link
				class="header-navMenu-item"
				:underline="false"
				@click="handleCommand('/home')"
				>首页</el-link
			>
			<el-dropdown
				class="header-navMenu-item"
				@command="handleCommand"
				@visible-change="visibleChange($event, 1)"
			>
				<span class="el-dropdown-link">
					解决方案<i
						class="iconfont"
						:class="
							isVisible1 ? 'icon-xiala-shang' : 'icon-xiala-xia'
						"
					></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item
						class="bg-color"
						v-for="(i, index_s) in solutionList"
						:command="`/solution?id=${i.planId}`"
						:key="index_s"
						>{{ i.planName }}</el-dropdown-item
					>
				</el-dropdown-menu>
			</el-dropdown>
			<el-dropdown
				class="header-navMenu-item"
				@command="handleCommand"
				@visible-change="visibleChange($event, 2)"
			>
				<span class="el-dropdown-link">
					产品中心<i
						class="iconfont"
						:class="
							isVisible2 ? 'icon-xiala-shang' : 'icon-xiala-xia'
						"
					></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item
						class="bg-color"
						v-for="(i, index_p) in productList"
						:command="`/product?id=${i.productId}`"
						:key="index_p"
						>{{ i.productName }}</el-dropdown-item
					>
				</el-dropdown-menu>
			</el-dropdown>
			<el-link
				class="header-navMenu-item"
				:underline="false"
				@click="handleClickOpen(openPlatformUrl, '_blank')"
				>开放平台</el-link
			>
			<el-dropdown
				class="header-navMenu-item"
				@command="handleCommand"
				@visible-change="visibleChange($event, 3)"
			>
				<span class="el-dropdown-link">
					体验中心<i
						class="iconfont"
						:class="
							isVisible3 ? 'icon-xiala-shang' : 'icon-xiala-xia'
						"
					></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item
						class="bg-color"
						v-for="(i, index_e) in experienceList"
						:command="i.keyPath"
						:key="index_e"
						@click="setExperienceIndex(i.id)"
						>{{ i.keyName }}</el-dropdown-item
					>
				</el-dropdown-menu>
			</el-dropdown>
			<el-dropdown
				class="header-navMenu-item"
				@command="handleCommand"
				@visible-change="visibleChange($event, 4)"
			>
				<span class="el-dropdown-link">
					关于我们<i
						class="iconfont"
						:class="
							isVisible4 ? 'icon-xiala-shang' : 'icon-xiala-xia'
						"
					></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item
						class="bg-color"
						v-for="(i, index) in AboutUsList"
						:command="i.keyPath"
						:key="index"
						>{{ i.keyName }}</el-dropdown-item
					>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<div class="header-iconMenu">
			<i class="iconfont icon-yincang" @click="clickSidebar"></i>
			<div class="header-sidebar" v-if="isSidebar">
				<el-menu
					:router="true"
					default-active="2"
					class="sidebar"
					:unique-opened="true"
					@select="handleSelect"
					background-color="rgba(25, 25, 25, 0.2)"
					text-color="#eee"
					active-text-color="#127BF2"
				>
					<el-menu-item class="iconNav-bg-color" index="/home"
						>首页</el-menu-item
					>
					<el-submenu index="2">
						<template slot="title">解决方案</template>
						<el-menu-item
							class="iconNav-bg-color"
							v-for="(i, index) in solutionList"
							:index="`/solution?id=${i.planId}`"
							:key="index"
							>{{ i.planName }}</el-menu-item
						>
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">产品中心</template>
						<el-menu-item
							class="iconNav-bg-color"
							v-for="(i, index) in productList"
							:index="`/product?id=${i.productId}`"
							:key="index"
							>{{ i.productName }}</el-menu-item
						>
					</el-submenu>
					<el-menu-item
						class="iconNav-bg-color"
						v-if="mobileTerminal"
						index="/experienceDemo"
						>体验中心</el-menu-item
					>
					<el-submenu index="4" v-if="!mobileTerminal">
						<template slot="title">体验中心</template>
						<el-menu-item
							class="bg-color"
							v-for="(i, index) in experienceList"
							:index="i.keyPath + ''"
							:key="index"
							@click="setExperienceIndex(i.id)"
							>{{ i.keyName }}</el-menu-item
						>
					</el-submenu>
					<el-submenu index="5">
						<template slot="title">关于我们</template>
						<el-menu-item
							class="iconNav-bg-color"
							v-for="(i, index) in AboutUsList"
							:index="i.keyPath + ''"
							:key="index"
							>{{ i.keyName }}</el-menu-item
						>
					</el-submenu>
				</el-menu>
			</div>
		</div>
	</div>
</template>

<script>
import {
	menuSolution,
	menuProduct,
	menuExperience,
	menuAboutUs,
} from '@/utils/mock.js'
export default {
	name: 'NewsInfo',
	data() {
		return {
			openPlatformUrl: window.openPlatformUrl,
			isSidebar: false,
			isCurrent: false,
			isVisible: false,
			activeName: '1',
			headerLogo: require('@/assets/img/jetvoice1.png'),
			headerLogoBlank: require('@/assets/img/jetvoice2.png'),
			experienceList: menuExperience,
			AboutUsList: menuAboutUs,
			htmlWidth: '',
			mobileTerminal: false,
			isVisible1: false,
			isVisible2: false,
			isVisible3: false,
			isVisible4: false,
		}
	},
	props: {
		productList: {
			type: Array,
			default: menuProduct,
		},
		solutionList: {
			type: Array,
			default: menuSolution,
		},
	},
	created() {},
	mounted() {
		if ((this, this._isMobile())) {
			this.mobileTerminal = true
		} else {
			this.mobileTerminal = false
		}
		this.htmlWidth = document.querySelector('html').offsetWidth

		window.addEventListener('scroll', this.handleSelect, true)
	},
	methods: {
		handleSelect() {
			this.isSidebar = false
		},
		handleOpen() {
			if (this.isMobileEnd) {
				this.isCurrent = false
			} else {
				this.isCurrent = true
			}
		},
		handleClose() {
			this.isCurrent = false
		},
		visibleChange(visible, type) {
			this.isVisible = visible
			switch (type) {
				case 1:
					this.isVisible1 = visible
					break
				case 2:
					this.isVisible2 = visible
					break
				case 3:
					this.isVisible3 = visible
					break
				case 4:
					this.isVisible4 = visible
					break
			}
		},
		handleCommand(command) {
			this.$router.push(command)
		},
		handleClickOpen(url, type) {
			window.open(url, type)
		},
		setExperienceIndex(index) {
			this.activeIndex = index
		},
		clickSidebar() {
			this.isSidebar = !this.isSidebar
		},

		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			)
			return flag
		},
	},
	components: {},
	computed: {
		// menuIndex() {
		// 	let path = this.$route.path
		// 	path = path.replace(/\?.*/g, '')
		// 	console.log('path', path)
		// 	return path
		// },
		activeIndex: {
			get() {
				return this.$store.state.activeIndex
			},
			set(val) {
				this.$store.state.activeIndex = val
			},
		},
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.header-wrap {
	// width: 100%;
	height: 0.64rem;
	padding: 0 0.3rem;
	box-sizing: border-box;
	// background: rgba($color: #111, $alpha: 0.2);
	display: flex;
	justify-content: space-around;
	align-items: center;

	.header-logo {
		height: 0.48rem;
		height: inherit;
		padding: 0.08rem;
		// margin: .1rem;
		box-sizing: border-box;
		img {
			// margin: .1rem;
			// float: left;
			width: inherit;
			height: 100%;
			object-fit: cover;
			display: block;
			// margin: auto;
		}
	}
	.header-iconMenu {
		display: none;
		// position: relative;
		i {
			display: block;
			color: #fff;
			font-size: 0.22rem;
		}
		.header-sidebar {
			position: fixed;
			top: 0.6rem;
			right: 0;
			width: 30%;
			.sidebar {
				background: rgba($color: #111, $alpha: 0);
				border: 0.01rem none #fff;
				z-index: 99;
				backdrop-filter: blur(0.04rem);
				::v-deep .el-submenu__title {
					height: 1.5rem;
					line-height: 1.5rem;
				}
				::v-deep .el-menu-item,
				.el-submenu__title {
					font-size: 0.16rem;
					line-height: 1.5rem;
					height: 1.5rem !important;
				}
				::v-deep .el-menu-item:hover,
				.el-submenu__title:hover {
					color: #127bf2 !important;
					background-color: rgba(
						$color: #000000,
						$alpha: 0.4
					) !important;
				}
				::v-deep .el-submenu {
					.el-submenu__title:hover,
					.el-menu-item:focus,
					.el-menu-item:hover {
						color: #127bf2 !important;
						background-color: rgba(
							$color: #000000,
							$alpha: 0.4
						) !important;
						i {
							color: #127bf2 !important;
						}
					}
				}
				::v-deep
					.is-opened
					.el-submenu__title
					.el-submenu__icon-arrow::before {
					color: #127bf2 !important;
				}
			}
		}
	}
	.header-navMenu {
		height: 100%;
		background: rgba($color: #111, $alpha: 0);
		border: 0.01rem none #fff;
		z-index: 99;
		display: flex;
		align-items: center;
		&-item {
			width: 0.9rem;
			font-weight: 400;
			font-size: 0.16rem;
			color: #fff;
			line-height: 0.4rem;
			margin: 0 0.16rem;
			text-align: center;
			.el-dropdown-link {
				i {
					display: inline-block;
					margin-left: 0.08rem;
				}
			}
		}
	}
}
.header-lucency {
	background: #fff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	.header-navMenu {
		&-item {
			color: #666;
			cursor: pointer;
			&:hover {
				color: #0078ff;
			}
		}
	}
	.header-iconMenu {
		i {
			color: #000;
		}
	}
}
.bg-color {
	font-family: Source Han Sans, Source Han Sans CN;
	font-size: 14px;
	padding: 5px 15px;
	color: #333333 !important;
	background-color: #f7f9ff !important;
	&:hover {
		color: #ffffff !important;
		background-color: #0078ff !important;
	}
}
.iconNav-bg-color {
	background-color: rgba($color: #000000, $alpha: 0.2) !important;
	&:hover {
		color: #127bf2 !important;
		background-color: rgba($color: #000000, $alpha: 0.4) !important;
	}
}
@media screen and (max-width: 1200px) {
	.header-wrap {
		display: flex;
		justify-content: space-between;
		padding: 0 10px 0 10px;
		.header-navMenu {
			display: none;
		}
		.header-iconMenu {
			display: block;
		}
	}
}

@media screen and (max-width: 768px) {
	.header-wrap {
		height: 44px;
		.header-logo {
			height: 24px;
			padding: 0 5px;
		}
		.header-iconMenu {
			i {
				font-size: 14px;
			}
			.header-sidebar {
				width: 100%;
				top: 44px;
				.sidebar {
					::v-deep .el-menu-item,
					.el-submenu__title {
						font-size: 14px;
					}
				}
			}
		}
	}
}
</style>
